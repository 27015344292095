import { Injectable } from '@angular/core';
import { Parent } from '@core/models/entities/parent.models';
import { QueryParams, PaginatedData } from '@wlcm/angular/core';
import { HttpApi } from './http.api';
import { Observable } from 'rxjs';
import { UpdateFamilyPayload } from '@features/families/models/family-update.models';

const feature: string = 'parent';

@Injectable({ providedIn: 'root' })
export class ParentApi {
  constructor(private readonly httpApi: HttpApi) {}

  getMy(): Observable<Parent> {
    return this.httpApi.get(`${feature}/my`);
  }

  getPaginated(params: QueryParams): Observable<PaginatedData<Parent>> {
    return this.httpApi.get(feature, { params });
  }

  addFamily(payload: UpdateFamilyPayload): Observable<void> {
    return this.httpApi.post(`${feature}/add`, payload);
  }

  removeFamily(id: string): Observable<void> {
    return this.httpApi.delete(`${feature}/${id}`);
  }
}
