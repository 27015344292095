import { Directive, Host, Self, Signal, contentChildren, effect } from '@angular/core';
import { MatMenu, MatMenuItem } from '@angular/material/menu';

@Directive({
  selector: '[napaMenu]',
  standalone: true,
})
export class MenuDirective {
  items: Signal<ReadonlyArray<MatMenuItem>> = contentChildren(MatMenuItem);

  constructor(@Host() @Self() private _menu: MatMenu) {
    this._menu.classList = 'napa-regular-menu';

    effect(() => this.disableRipple(this.items()));
  }

  private disableRipple(items: ReadonlyArray<MatMenuItem>): void {
    items.forEach((item: MatMenuItem) => {
      item.disableRipple = true;
    });
  }
}
